import moment from 'moment';
import * as firestore from 'firebase/firestore';

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const sign = bytes < 0 ? -1 : 1;
    const i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(k));
    return sign * parseFloat((Math.abs(bytes) / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};


export const formatDate = (date: firestore.Timestamp | Date, pattern = 'DD.MM.YYYY') => {
    if (date) {
        if (date instanceof Date) { // It's a Date object
            return moment(date).format(pattern);
        } else if (date instanceof firestore.Timestamp) {
            return moment(date.toDate()).format(pattern);
        }
    }
};
