import { BaseModelComponent } from './base-model.component';
import { OnDestroy, OnInit, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import * as lodash from 'lodash';

@Component({
    template: ''
})
export abstract class BaseItemComponent extends BaseModelComponent implements OnInit, OnDestroy {

    public item: any = {};
    public itemSubscription: Subscription;

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.getItem();
    }

    ngOnDestroy(): void {
        if (this.itemSubscription) {
            this.itemSubscription.unsubscribe();
        }
    }

    /**
     * Get an item by its ID from firestore.
     */
    protected getItem(): void {
        const id = this.getParamId();
        if (id) {
            this.spinner.show();
            this.itemSubscription = this.service.getById(id, this.getCollectionURL())
            .subscribe({
                next: async (result) => {
                    if (lodash.isNil(result)) {
                        this.notification.notFoundError();
                        this.backToList();
                    }
                    if (result?.pendentChanges) {
                        const pendentChanges = await result?.pendentChanges?.get().then((res: any) => res.data());
                        this.item = {id, ...pendentChanges};
                    } else {
                        this.item = {id, ...result};
                    }
                    this.spinner.hide();
                    this.postGetItem();
                    this.applyMasks();
                },
                error: (err) => {
                    console.error(err);
                    this.spinner.hide();
                }
            });
        }
    }

    public backToList(): void {
        this.navigate([this.getRouterURL()]);
    }

    protected postGetItem(): void {
    }

    protected getParamId(): string {
        return this.getEmployeeId(this.getItemIdKey());
    }

    protected getItemIdKey(): string {
        return 'id';
    }
}
