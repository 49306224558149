import {AppInjector} from '../util/app.injector';
import {BaseService} from './base-service';
import { OnInit, Component } from '@angular/core';
import {BaseComponent} from './base.component';
import {ActivatedRoute} from '@angular/router';

@Component({
    template: ''
})
export abstract class BaseModelComponent extends BaseComponent implements OnInit {

    protected service: BaseService<any> = AppInjector.get(BaseService);

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    /**
     * Get an specific param from URL
     * @param param The desired param
     */
    protected getEmployeeId(param: string): string {
        return this.getActivatedRoute()
            ? this.getActivatedRoute().snapshot.paramMap.get(param)
            : null;
    }

    protected getActivatedRoute(): ActivatedRoute {
        return null;
    }

    abstract getCollectionURL(): string;
    abstract getRouterURL(): string;
}
