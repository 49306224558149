import { AfterViewInit, OnDestroy, OnInit, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { AppInjector } from '../util/app.injector';
import { BaseItemComponent } from './base-item.component';
import { FunctionOptions } from '../domain/function-options';
import { InfoDialogComponent } from '../components/info-dialog/info-dialog.component';
import {
    APPROVE_REQUESTS_FOR_BOOKKEEPING_KEYS_FUNCTION,
    APPROVE_REQUESTS_FOR_COMPANY_MASTER_DATA_FUNCTION,
    APPROVE_REQUESTS_FOR_PAYROLL_ACCOUNTING_FUNCTION,
    PERM_CONFIG_PAYROLL_ACCOUNTING,
    PERM_DEFINE_BOOKING_KEY,
    PERM_EDIT_COMPANY_MASTER_DATA
} from '../../app.constants';
import { ChangeHistoryMappedModel } from '../../shared/models/change-history.model';
import { MatDialog } from '@angular/material/dialog';

declare let $: any;

@Component({
    template: ''
})
export abstract class BaseEditComponent extends BaseItemComponent implements OnInit, OnDestroy, AfterViewInit {

    functionsToApprovePendentChanges = {
        [PERM_DEFINE_BOOKING_KEY]: APPROVE_REQUESTS_FOR_BOOKKEEPING_KEYS_FUNCTION,
        [PERM_CONFIG_PAYROLL_ACCOUNTING]: APPROVE_REQUESTS_FOR_PAYROLL_ACCOUNTING_FUNCTION,
        [PERM_EDIT_COMPANY_MASTER_DATA]: APPROVE_REQUESTS_FOR_COMPANY_MASTER_DATA_FUNCTION
    };

    private createItemsSubscription;
    private updateItemsSubscription;

    protected dialog: MatDialog = AppInjector.get(MatDialog);
    protected formBuilder: UntypedFormBuilder = AppInjector.get(UntypedFormBuilder);

    public editForm;
    public isEditMode = false;
    public submitted = false;

    protected constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isEditMode = !!this.getParamId();
        this.initForm();
        if (this.isViewMode) {
            this.editForm.disable({emitEvent: false});
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.applyMasks();
    }

    protected applyMasks(): void {
        super.applyMasks();
        $('input').blur((event) => {
            this.removeEmptySpaces(event);
        });
    }

    /**
     * Destroy all subscription to avoid duplications
     */
    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.createItemsSubscription) {
            this.createItemsSubscription.unsubscribe();
        }
        if (this.updateItemsSubscription) {
            this.updateItemsSubscription.unsubscribe();
        }
    }

    initForm(): void {
        this.editForm = this.formBuilder.group(this.getFormControls(), this.getFormOptions());
    }

    public removeEmptySpaces(event: any, formGroupName?: string) {
        const fieldName = event.currentTarget.ariaLabel;
        const form = formGroupName ? this.editForm.get(formGroupName) : this.editForm;
        if (form.get([fieldName])) {
            const {value} = form.get([fieldName]);
            if (value) {
                form.get([fieldName]).setValue(value.toString().trim());
            }
            return form;
        }
    }

    onSubmit(): void {
        if (this.editForm.valid) {
            this.submitted = true;
            if (!this.item.id) {
                this.preInsert();
                this.unmaskFields();
                this.insert();
            } else {
                this.preUpdate();
                this.unmaskFields();
                this.update();
            }
        } else {
            this.notification.error('operations.insert.review-required-fields');
            this.editForm.markAllAsTouched();
        }
    }

    canSubmit() {
        return this.editForm.valid && this.editForm.dirty;
    }

    /**
     * Object that contains the configuration to call some function when saving or updating an item.
     */
    getFunctionOptions(): FunctionOptions {
        return null;
    }

    protected getFormValue() {
        return this.editForm.value;
    }

    hasError(field: AbstractControl) {
        if (field.invalid && (field.dirty || field.touched)) {
            return 'has-error';
        }
    }

    /**
     * Request to insert an item in the firestore.
     */
    protected insert(): void {
        if (this.editForm.valid) {
            this.spinner.show();
            this.createItemsSubscription = this.service.create(
                this.getFormValue(),
                this.getCreateFunctionName(),
                this.getFunctionOptions()
            ).subscribe({
                next: (res) => {
                    this.spinner.hide();
                    this.postInsert();
                    this.notification.showToast(res?.code, res?.message);
                    this.submitted = true;
                    if (res.approvalRequest) {
                        this.dialog.open(InfoDialogComponent, {
                            panelClass: 'curved-modal',
                            data: {
                                text: 'messages.approval-request-created',
                                isConfirmationDialog: false,
                            }
                        });
                    }
                    this.backToList();
                },
                error: (error) => {
                    this.spinner.hide();
                    this.editForm.markAllAsTouched();
                    this.submitted = false;
                    const message = error.error ? error.error.message : error.message;
                    if (message) {
                        this.notification.showToast(error.code, message);
                        this.fireLogginService.sendErrorLog(`An error occurred while inserting item with function [${this.getCreateFunctionName()}], details: ${message}`);
                    } else {
                        this.notification.insertError();
                        this.fireLogginService.sendErrorLog(`An error occurred while inserting item with function [${this.getCreateFunctionName()}], details: ${JSON.stringify(error)}`);
                    }
                }
            });
        } else {
            this.notification.error('operations.insert.review-required-fields');
            this.editForm.markAllAsTouched();
        }
    }

    /**
     * Request to update an item in the firestore.
     */
    protected update(): void {
        if (this.editForm.valid) {
            this.spinner.show();
            this.updateItemsSubscription = this.service.update(
                this.item.id,
                this.getFormValue(),
                this.getUpdateFunctionName(),
                this.getFunctionOptions()
            ).subscribe(
                (res) => {
                    this.spinner.hide();
                    this.notification.showToast(res?.code, res?.message);
                    this.handleUpdate();
                    if (res.approvalRequest) {
                        this.dialog.open(InfoDialogComponent, {
                            panelClass: 'curved-modal',
                            data: {
                                text: 'messages.approval-request-created',
                                isConfirmationDialog: false,
                            }
                        });
                    }
                },
                (error) => {
                    this.spinner.hide();
                    this.editForm.markAllAsTouched();
                    this.submitted = false;
                    const message = error.error ? error.error.message : error.message;
                    if (message) {
                        this.notification.error(message);
                        this.fireLogginService.sendErrorLog(`An error occurred while udpating item with function ${this.getUpdateFunctionName()}, details: ${message}`);
                    } else {
                        this.notification.updateError();
                        this.fireLogginService.sendErrorLog(`An error occurred while udpating item with function ${this.getUpdateFunctionName()}, details: ${JSON.stringify(error)}`);
                    }
                }
            );
        } else {
            this.notification.error('operations.insert.review-required-fields');
            this.editForm.markAllAsTouched();
        }
    }

    /**
     * Manage the app's flow after updating an item successfully.
     */
    protected handleUpdate(): void {
        this.postUpdate();
        this.submitted = true;
        this.backToList();
    }

    protected preInsert(): void {
    }

    protected postInsert(): void {
    }

    protected preUpdate(): void {
    }

    protected postUpdate(): void {
    }

    abstract getFormControls(): object;

    abstract getCreateFunctionName(): string;

    abstract getUpdateFunctionName(): string;

    protected getFormOptions(): object {
        return {};
    }

    protected unmaskFields(): void {
    }

    canDeactivate(): boolean {
        return this.submitted || !this.editForm.dirty;
    }

    protected setChangeHistoryData(changeHistory: ChangeHistoryMappedModel, params?: any) {
        if (this.item && this.item.id === changeHistory.referenceId) {
            this.item.historicalData = changeHistory;
        }
    }

}
